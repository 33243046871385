import React, {useState, useEffect, useContext} from 'react';
import UserContext from '../common/UserContext.js';

// css
import '../../assets/css/EADashboard.css';

// kendo react
import {
    Chart,
    ChartTitle,
    ChartTooltip,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartCategoryAxisTitle,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartSeries,
    ChartSeriesItem
} from '@progress/kendo-react-charts';
import {formatNumber} from '@progress/kendo-intl';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    hostsKey,
    yearKey,
    partiallyCompatibleKey,
    mainMessages
} from '../../assets/text/MultilingualText.js';

function HostUtilizationChart(props) {
    const {
        eA,
        subscriptionPool,
        currency,
        chartColors,
        dateRange
    } = props;
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const [hosts, setHosts] = useState([]);

    const PARTIALLY_COMPATIBLE_KEY = localization.toLanguageString(partiallyCompatibleKey, mainMessages[siteLanguageDefault][partiallyCompatibleKey]);

    // amount tooltip
    const amountTooltip = ({point}) => {
        let data = point.dataItem;
        let pool = subscriptionPool.find(p => p.period === eA.period.period);
        let total = pool.total;
        let cost = data.total_cost;
        let percent = ((cost/total) * 100).toFixed(2);
        return <span>{formatNumber(cost, {style:"currency"})} {percent}%</span>;
    }

    // on load - create host data from period (periodic) and date range (variable)
    useEffect(() => {
        let pool = [];
        let subscriptionPoolProducts = [];
        let hostData = [];
        // get pool based on year and filter products on date range for variable ea's
        if (eA.name?.remix_type?.toUpperCase() === "VARIABLE") {
            pool = subscriptionPool.find(p => p.year === eA.year);
            subscriptionPoolProducts = pool.products.filter(p => new Date(p.license_start_date) >= dateRange.start && new Date(p.license_end_date) <= dateRange.end) || [];
        }
        // get pool based on period for periodic ea's
        else {
            pool = subscriptionPool.find(p => p.period === eA.period.period);
            subscriptionPoolProducts = pool?.products || [];
        }

        // add host total cost
        if (pool && subscriptionPoolProducts.length) {
            subscriptionPoolProducts.forEach(p => {
                // handle partially compatible products
                if (p.ea_compatibility === 'P') {
                    let partialIndex = hostData.findIndex(h => h.host_id === PARTIALLY_COMPATIBLE_KEY);
                    if (hostData[partialIndex]) {
                        hostData[partialIndex].total_cost += p.total_cost;
                    } else {
                        hostData.push({
                            host_id: PARTIALLY_COMPATIBLE_KEY,
                            total_cost: p.total_cost
                        });
                    }
                }
                else {
                    let index = hostData.findIndex(h => h.host_id === p.host_id);
                    if (hostData[index]) {
                        hostData[index].total_cost += p.total_cost;
                    } else if (p.host_id) {
                        hostData.push({
                            host_id: p.host_id,
                            total_cost: p.total_cost
                        });
                    }
                }
            });
        }
        setHosts(hostData);
    }, [eA.period, dateRange]);

    useEffect(() => {
        console.log("hosts", hosts);
    }, [hosts]);

    return (
        <Chart
            className={"ea-dashboard-h-50"}
            seriesColors={chartColors}
        >
            {!!eA?.year && <ChartTitle text={localization.toLanguageString(yearKey, mainMessages[siteLanguageDefault][yearKey]) + " " + eA.year}/>}
            <ChartTooltip render={amountTooltip}/>
            {/* budget utilization by host x-axis | cost */}
            <ChartValueAxis>
                <ChartValueAxisItem
                    name={"currency"}
                    min={0}
                    max={Math.max(...hosts.map(h => h.total_cost))}
                    labels={{format: "n"}}
                >
                    <ChartCategoryAxisTitle text={currency}/>
                </ChartValueAxisItem>
            </ChartValueAxis>
            {/* budget utilization by host y-axis | hosts */}
            <ChartCategoryAxis>
                <ChartCategoryAxisItem categories={hosts.map(h => h.host_id)}>
                    <ChartCategoryAxisTitle text={localization.toLanguageString(hostsKey, mainMessages[siteLanguageDefault][hostsKey])}/>
                </ChartCategoryAxisItem>
            </ChartCategoryAxis>
            {/* budget utilization by host bar series */}
            <ChartSeries>
                <ChartSeriesItem
                    type="bar"
                    data={hosts}
                    field={"total_cost"}
                    categoryField={"host_id"}
                />
            </ChartSeries>
        </Chart>
    );
};

export default HostUtilizationChart;