import React, {useState, useEffect, useContext} from 'react';
import UserContext from '../common/UserContext.js';

// css
import '../../assets/css/EADashboard.css';

// kendo react
import {
    Chart,
    ChartTitle,
    ChartTooltip,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartCategoryAxisTitle,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartSeries,
    ChartSeriesItem
} from '@progress/kendo-react-charts';
import {formatNumber} from '@progress/kendo-intl';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    productsKey,
    allOtherProductsKey,
    yearKey,
    mainMessages
} from '../../assets/text/MultilingualText.js';

function ProductUtilizationChart(props) {
    const {
        eA,
        subscriptionPool,
        currency,
        chartColors,
        dateRange
    } = props;
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const [products, setProducts] = useState([]);

    // amount tooltip
    const amountTooltip = ({point}) => {
        let data = point.dataItem;
        let pool = subscriptionPool.find(p => p.period === eA.period.period);
        let total = pool.total;
        let cost = data.total_cost;
        let percent = ((cost/total) * 100).toFixed(2);
        return <span>{formatNumber(cost, {style:"currency"})} {percent}%</span>;
    }

    // on load - create product data from period (periodic) and date range (variable)
    useEffect(() => {
        let pool = [];
        let subscriptionPoolProducts = [];
        let productData = [];
        let top10 = [];
        // get pool based on year and filter products on date range for variable ea's
        if (eA.name?.remix_type?.toUpperCase() === "VARIABLE") {
            pool = subscriptionPool.find(p => p.year === eA.year);
            subscriptionPoolProducts = pool.products.filter(p => new Date(p.license_start_date) >= dateRange.start && new Date(p.license_end_date) <= dateRange.end) || [];
        }
        // get pool based on period for periodic ea's
        else {
            pool = subscriptionPool.find(p => p.period === eA.period.period);
            subscriptionPoolProducts = pool?.products || [];
        }

        // add product total cost
        if (pool && subscriptionPoolProducts.length) {
            subscriptionPoolProducts.forEach(p => {
                let index = productData.findIndex(product => product.product_id === p.product_id);
                if (productData[index]) {
                    productData[index].total_cost += p.total_cost;
                } else if (p.product_id) {
                    productData.push({
                        product_id: p.product_id,
                        product_name: p.prod_num,
                        total_cost: p.total_cost
                    });
                }
            });

            productData.sort((a, b) => b.total_cost - a.total_cost);

            // get top 10 products
            top10 = productData.slice(0, 10);
            // case: handle remaining products spent
            if (productData.length > 10) {
                let remaining = productData.slice(10, productData.length);
                top10.push({
                    product_name: localization.toLanguageString(allOtherProductsKey, mainMessages[siteLanguageDefault][allOtherProductsKey]),
                    total_cost: remaining.reduce((accumulator, product) => accumulator + product.total_cost, 0)
                });
            }
        }
        setProducts(top10);
    }, [eA.period, dateRange]);

    useEffect(() => {
        console.log("top 10 products", products);
    }, [products]);

    return (
        <Chart
            className={"ea-dashboard-h-50"}
            seriesColors={chartColors}
        >
            {!!eA?.year && <ChartTitle text={localization.toLanguageString(yearKey, mainMessages[siteLanguageDefault][yearKey]) + " " + eA.year}/>}
            <ChartTooltip render={amountTooltip}/>
            {/* top 10 products spent x-axis | cost */}
            <ChartValueAxis>
                <ChartValueAxisItem
                    name={"currency"}
                    min={0}
                    max={Math.max(...products.map(p => p.total_cost))}
                    labels={{format: "n"}}
                >
                    <ChartCategoryAxisTitle text={currency}/>
                </ChartValueAxisItem>
            </ChartValueAxis>
            {/* top 10 products spent y-axis | products */}
            <ChartCategoryAxis>
                <ChartCategoryAxisItem categories={products.map(p => p.product_name)}>
                    <ChartCategoryAxisTitle text={localization.toLanguageString(productsKey, mainMessages[siteLanguageDefault][productsKey])}/>
                </ChartCategoryAxisItem>
            </ChartCategoryAxis>
            {/* top 10 products spent bar series */}
            <ChartSeries>
                <ChartSeriesItem
                    type="bar"
                    data={products}
                    field={"total_cost"}
                    categoryField={"product_name"}
                />
            </ChartSeries>
        </Chart>
    );
};

export default ProductUtilizationChart;