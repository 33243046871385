import React, {useContext} from 'react';
import {GetResponseText} from "./GetResponseText";
import {
    mainMessages
} from "../../../assets/text/MultilingualText";
import UserContext from "../UserContext";
import {useLocalization} from "@progress/kendo-react-intl";
import Alert from "../Alert";


export const RequestAlerts = (props) => {
    const {
        isVisibleAlerts,
        setIsVisibleAlerts
    } = props
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem'
            }}
        >
            {Object.keys(isVisibleAlerts).map(key => {
                const alertInfo = GetResponseText(key);
                const alertType = alertInfo['alertType'];
                const title = alertInfo['title'];
                const message = alertInfo['message'];
                const showHandler = () => {
                    setIsVisibleAlerts(isVisibleAlerts => ({
                        ...isVisibleAlerts,
                        [key]: false
                    }))
                }
                if (isVisibleAlerts[key]) {
                    return (
                        <Alert
                            type={alertType}
                            showHandler={() => {
                                showHandler()
                            }}
                            title={localization.toLanguageString(title, mainMessages[siteLanguageDefault][title])}
                            message={localization.toLanguageString(message, mainMessages[siteLanguageDefault][message])}
                        />
                    )
                } else {
                    return null
                }
            })}
        </div>
    )
}
