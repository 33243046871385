import React, {useContext} from 'react';
import UserContext from '../common/UserContext.js';

// css
import '../../assets/css/EADashboard.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// kendo react
import {
    Chart,
    ChartTitle,
    ChartTooltip,
    ChartLegend,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartCategoryAxisTitle,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesItemTooltip,
    ChartSeriesLabels
} from '@progress/kendo-react-charts';
import {SvgIcon} from '@progress/kendo-react-common';
import {chevronLeftIcon, chevronRightIcon} from '@progress/kendo-svg-icons';
import {formatNumber} from '@progress/kendo-intl';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    yearKey,
    periodsKey,
    consumedSubscriptionPoolKey,
    totalSubscriptionPoolKey,
    mainMessages
} from '../../assets/text/MultilingualText.js';

function PeriodicBudgetUtilizationChart(props) {
    const {
        eA,
        pool,
        years,
        currency,
        chartColors,
        onEAChange
    } = props;
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    // amount and percent tooltips
    const amountTooltip = ({point}) => <span>{formatNumber(point.value, {style:"currency"})}</span>;
    const percentTooltip = (e) => `${(e.point.value * 100).toFixed(2)}%`;

    return (
        <Row>
            <Col
                xs={1}
                className={"px-0 align-content-center ea-dashboard-icon-button"}
            >
                {!!years.length && <SvgIcon
                    className={"ea-dashboard-icon-button"}
                    icon={chevronLeftIcon}
                    size={"xxlarge"}
                    onClick={(e) => onEAChange(e, 'prev')}
                />}
            </Col>
            <Col>
                <Chart
                    className={"ea-dashboard-h-50"}
                    seriesColors={chartColors}
                >
                    <ChartTitle text={eA.year ? localization.toLanguageString(yearKey, mainMessages[siteLanguageDefault][yearKey]) + " " + eA.year
                        : localization.toLanguageString(yearKey, mainMessages[siteLanguageDefault][yearKey])}/>
                    <ChartTooltip render={percentTooltip}/>
                    <ChartLegend
                        position={"bottom"}
                        orientation={"horizontal"}
                    />
                    <ChartCategoryAxis>
                        {/* total vs utilized budget x-axis | periods */}
                        <ChartCategoryAxisItem
                            categories={pool.map(p => p.period_date)}
                            axisCrossingValue={pool.length ? [0, pool.length] : []}
                            labels={{rotation: 'auto'}}
                        >
                            <ChartCategoryAxisTitle text={localization.toLanguageString(periodsKey, mainMessages[siteLanguageDefault][periodsKey])} />
                        </ChartCategoryAxisItem>
                    </ChartCategoryAxis>
                    <ChartValueAxis>
                        {/* total vs utilized budget y-axis | currency and percent */}
                        <ChartValueAxisItem
                            name={"currency"}
                            min={0}
                            max={Math.max(...pool.map(p => p.total))}
                            labels={{format: "n"}}
                        >
                            <ChartCategoryAxisTitle text={currency} />
                        </ChartValueAxisItem>
                        <ChartValueAxisItem
                            name={"percentage"}
                            min={0}
                            max={1}
                            labels={{format: "p"}}
                        />
                    </ChartValueAxis>
                    <ChartSeries>
                        {/* total vs. utilized budget bar series */}
                        <ChartSeriesItem
                            name={localization.toLanguageString(consumedSubscriptionPoolKey, mainMessages[siteLanguageDefault][consumedSubscriptionPoolKey])}
                            type={"column"}
                            data={pool}
                            field={"consumed"}
                            gap={2}
                            spacing={0.25}
                        >
                            <ChartSeriesItemTooltip render={amountTooltip}/>
                        </ChartSeriesItem>
                        <ChartSeriesItem
                            name={localization.toLanguageString(totalSubscriptionPoolKey, mainMessages[siteLanguageDefault][totalSubscriptionPoolKey])}
                            type={"column"}
                            data={pool}
                            field={"total"}
                            gap={2}
                            spacing={0.25}
                        >
                            <ChartSeriesItemTooltip render={amountTooltip}/>
                        </ChartSeriesItem>
                        {/* total vs. utilized budget line series */}
                        <ChartSeriesItem
                            type={"line"}
                            data={pool}
                            field={"percent"}
                            name={"Percentage"}
                            axis={"percentage"}
                        >
                            <ChartSeriesLabels
                                position={"above"}
                                format={"p"}
                            />
                            <ChartSeriesItemTooltip render={percentTooltip}/>
                        </ChartSeriesItem>
                    </ChartSeries>
                </Chart>
            </Col>
            <Col
                xs={1}
                className={"px-0 align-content-center ea-dashboard-icon-button"}
            >
                {!!years.length && <SvgIcon
                    className={"ea-dashboard-icon-button ea-dashboard-icon-button"}
                    icon={chevronRightIcon}
                    size="xxlarge"
                    onClick={(e) => onEAChange(e, 'next')}
                />}
            </Col>
        </Row>
    );
};

export default PeriodicBudgetUtilizationChart;