import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment';
import UserContext from '../common/UserContext.js';

// css
import '../../assets/css/EADashboard.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// kendo react
import {
    Chart,
    ChartTitle,
    ChartTooltip,
    ChartCategoryAxisTitle,
    ChartSeries,
    ChartSeriesItem,
    ChartXAxis,
    ChartXAxisItem,
    ChartYAxis,
    ChartYAxisItem
} from '@progress/kendo-react-charts';
import {Path, Group, Text} from '@progress/kendo-drawing';
import {SvgIcon} from '@progress/kendo-react-common';
import {chevronLeftIcon, chevronRightIcon} from '@progress/kendo-svg-icons';
import {formatNumber} from '@progress/kendo-intl';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    yearKey,
    totalPoolKey,
    mainMessages
} from '../../assets/text/MultilingualText.js';

function VariableBudgetUtilizationChart(props) {
    const {
        eA,
        pool,
        years,
        currency,
        chartColors,
        onEAChange
    } = props;
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const [totalCost, setTotalCost] = useState([]);

    // subscription pool render
    const subscriptionPoolRender = (args) => {
        let subPool = pool.find(p => p.year === eA.year);
        const chart = args.target.chartInstance;
        if (!chart) {
            return;
        }

        // get the axes
        const yAxis = chart.findAxisByName('currency');
        const xAxis = chart.findAxisByName('months');

        // get the coordinates of the value at which the plot band will be rendered
        const ySlot = yAxis.slot(subPool?.total);

        // get the coordinates of the entire category axis range
        const range = xAxis.range();
        const xSlot = xAxis.slot(range.min, range.max);

        // draw the plot band based on the found coordinates
        const line = new Path({
            stroke: {
                color: 'var(--keysight-primary)',
                width: 2
            }
        })
            .moveTo(ySlot.origin.x, ySlot.origin.y)
            .lineTo(xSlot.topRight().x, ySlot.origin.y);

        // subscription pool label
        const label = new Text(
            localization.toLanguageString(totalPoolKey, mainMessages[siteLanguageDefault][totalPoolKey])
                + " (" + formatNumber(subPool?.total, {style: "currency"}) + ")",
            [0, 0],
            {fill: {color: 'var(--keysight-primary)'}}
        );

        const bbox = label.bbox();
        label.position([
            xSlot.topRight().x - bbox.size.width,
            ySlot.origin.y - bbox.size.height,
        ]);
        const group = new Group();
        group.append(line, label);

        // draw on the surface
        chart.surface.draw(group);
    };

    // total cost tooltip
    const totalCostTooltip = ({point}) => {
        let data = point.dataItem;
        let cost = data.total_cost;
        let date = moment(data.date).format('ll');
        return <span>{date} {formatNumber(cost, {style: "currency"})}</span>
    }

    // create variable budget utilization chart data
    useEffect(() => {
        let subPool = pool.find(p => p.year === eA.year);
        let totals = [];
        if (subPool) {
            // create array of objects for every day from start to end date
            let start = new Date(subPool.start_date);
            let end = new Date(subPool.end_date);
            let current = start;
            while (current <= end) {
                totals.push({
                    date: new Date(current),
                    total_cost: 0
                });
                current.setDate(current.getDate() + 1);
            }

            // increment the total cost for each day product is active (ie. product's start to end date)
            subPool.products.forEach(product => {
                // filter for all the dates a product is active
                let active = totals.filter(cost => cost.date >= product.start_date && cost.date <= product.end_date);
                // increment cost for each date a product is active
                active.forEach(a => {
                    let index = totals.findIndex(t => t.date === a.date);
                    if (totals[index]) {
                        let cost = isNaN(product.total_cost) ? 0 : product.total_cost;
                        totals[index].total_cost += cost;
                    }
                });
            })
        }

        setTotalCost(totals);
    }, [pool]);

    return (
        <Row>
            <Col
                xs={1}
                className={"px-0 align-content-center ea-dashboard-icon-button"}
            >
                {!!years.length && <SvgIcon
                    className={"ea-dashboard-icon-button"}
                    icon={chevronLeftIcon}
                    size={"xxlarge"}
                    onClick={(e) => onEAChange(e, 'prev')}
                />}
            </Col>
            <Col>
                <Chart
                    className={"ea-dashboard-h-50"}
                    seriesColors={chartColors}
                    onRender={subscriptionPoolRender}
                >
                    <ChartTitle text={eA.year ? localization.toLanguageString(yearKey, mainMessages[siteLanguageDefault][yearKey]) + " " + eA.year
                        : localization.toLanguageString(yearKey, mainMessages[siteLanguageDefault][yearKey])}/>
                    <ChartTooltip render={totalCostTooltip}/>
                     {/* total cost x-axis | months */}
                    <ChartXAxis>
                        <ChartXAxisItem
                            baseUnit={"months"}
                            name={"months"}
                            majorUnit={1}
                        />
                    </ChartXAxis>
                    <ChartYAxis>
                        {/* subscription pool plot band */}
                        <ChartYAxisItem
                            labels={{format: "n"}}
                            max={pool.find(p => p.year === eA.year) ? pool.find(p => p.year === eA.year).total : null}
                            name={"currency"}
                        >
                            <ChartCategoryAxisTitle text={currency} />
                        </ChartYAxisItem>
                    </ChartYAxis>
                    {/* product cost line series */}
                    <ChartSeries>
                        <ChartSeriesItem
                            type={"scatterLine"}
                            yField={"total_cost"}
                            xField={"date"}
                            data={totalCost}
                            style={"step"}
                        />
                    </ChartSeries>
                </Chart>
            </Col>
            <Col
                xs={1}
                className={"px-0 align-content-center ea-dashboard-icon-button"}
            >
                {!!years.length && <SvgIcon
                    className={"ea-dashboard-icon-button ea-dashboard-icon-button"}
                    icon={chevronRightIcon}
                    size="xxlarge"
                    onClick={(e) => onEAChange(e, 'next')}
                />}
            </Col>
        </Row>
    );
};

export default VariableBudgetUtilizationChart;